import React from 'react';
import styled from 'styled-components';
import ListItemContainer from '../../components/ThankyouPages/ListItemContainer';
// import AdButler from '../../components/ThankyouPages/AdButler';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const EpbContainer = styled.div`
  text-align: center;

  h1 {
    font-size: 22px;
    font-weight: 400;
  }

  img {
    width: 90%;
    max-width: 450px;
    margin: 20px auto;
  }
`;

const SubSection = styled.section`
  width: calc(96% - 40px);
  max-width: 660px;
  padding: 10px 20px 10px;
  margin: auto;
  border-radius: 10px;
  box-shadow: 0 3px 8px #555;
  margin-bottom: 10px;

  background: ${({ background }) => background && background};
  border: ${({ border }) => border && border};
  color: ${({ color }) => color && color};

  h2 {
    text-align: center;
    padding: 0;
    margin: 0 0 10px 0;
    line-height: 1;
  }
`;

const ContactBlock = styled.div`
  width: calc(100vw - 40px);
  max-width: 700px;
  margin: auto;
  text-align: center;
  button {
    width: 100%;
    margin: 10px auto;
    height: 60px;
    color: #fff;
    background: #002856;
    border-radius: 5px;
    font-size: 20px;
    outline: none;
    transition: background 0.3s;
    cursor: pointer;
  }
  p {
    font-size: 25px;
  }
  .bold {
    font-weight: 900;
  }
`;

const sectionOne = [
  'Paramount Bank is Veteran owned and an FDIC insured bank headquartered in St. Louis, MO. We’ve been helping customers for almost 50 years and will be calling you to do the same for a no-hassle or obligation home loan analysis to help you',
  'We proudly have a 4.92 out of 5* average client satisfaction score and 97.6%* would recommend us to friends and family.',
  'Paramount Bank eliminates the hassles of the mortgage process when buying or refinancing, replacing it with a convenient, secure, FDIC-insured, and unparalleled experience to help you get the home or cash-flow you deserve.',
];

const sectionTwo = [
  'Can I expect a lower monthly payment?',
  'Will I close within 30 days?',
  'Can I manage my mortgage online?',
  'Should I switch to a 15-year fixed?',
  'Will I get updates that will keep my informed?',
];

// function to send the user to paramount banks website
const handleClick = () => {
  window.open('https://corporate.paramountbank.com/', '_blank');
};

const EpbThankyou = () => (
  <EpbContainer>
    <h1>You've been matched with our featured provider, Paramount Bank</h1>
    <img
      src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/paramount-bank-logo.png"
      alt="Paramount Bank"
    />

    <SubSection background="#a9aeb2" border="4px solid #1c3d5c" color="#000">
      <h2>More about Paramount Bank</h2>
      <ListItemContainer items={sectionOne} />
    </SubSection>
    <SubSection background="#EC5B2A" border="4px solid #fff" color="#fff">
      <h2>5 Questions Paramount Bank Can Answer For You:</h2>
      <ListItemContainer items={sectionTwo} />
    </SubSection>

    <ContactBlock>
      <button type="button" onClick={handleClick}>
        Apply Online Now
      </button>
      <p>- or -</p>
      <p>
        <span className="bold">Call Now:</span>
        {'  '}(888) 908-0467
      </p>
    </ContactBlock>

    {/* <AdButler adButlerIds={adIds} /> */}
  </EpbContainer>
);

export default withThankyouWrapper(EpbThankyou);
